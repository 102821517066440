<home-navbar theme="light"></home-navbar>

<header class="header text-white bg-dark pt-9 pb-5"
    style="background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);">
    <div class="container">
        <h3> <a href="javascript:;" (click)="back()"><i class="fa fa-angle-left"></i></a>&nbsp;&nbsp; {{ title }}</h3>
    </div>
</header>

<!-- Main Content -->
<main class="main-content">
    <router-outlet></router-outlet>
</main>

<home-footer></home-footer>