import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class SuperAdminGuard  {
  constructor(private readonly router: Router, private readonly authService: AuthService) { }

  canActivate(_activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isSuperAdmin()) {
      return true;
    }
    this.router.navigate(['/login'], { queryParams: { redirect: state.url } });
    return false;
  }
}
