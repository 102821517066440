import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "docs-module-layout",
  templateUrl: "./module-layout.component.html",
  styleUrls: ["./module-layout.component.scss"],
})
export class ModuleLayoutComponent implements OnInit {
  title: string;

  constructor(private route: ActivatedRoute, private location: Location) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (data.title) this.title = data.title;
    });
  }

  back(): void {
    this.location.back();
  }
}
