<home-navbar></home-navbar>

<home-header></home-header>

<!-- Main Content -->
<main class="main-content">

  <section class="section py-9">
    <div class="container">
      <header class="section-header">
        <h2>Sweet Modules</h2>
      </header>
      <div class="row gap-y text-center">

        <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" routerLink="/sales">
            <p><i class="ti-money lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">Sales</h5>
            <p class="mb-0">Everything you need to know about your business performance. Check new vs existing
              customers, how you are performing against your goals and much more.</p>
          </a>
        </div>

        <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" routerLink="/roi">
            <p><i class="ti-bar-chart lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">ROI</h5>
            <p class="mb-0">Analyse your marketing performance mixing sales attribution with cost reporting, and
              understand which channels are performing the best and reporting the highest ROI.</p>
          </a>
        </div>

        <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" routerLink="/customers">
            <p><i class="ti-user lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">Customers</h5>
            <p class="mb-0">The customers module provides insights into customer-related metrics, retention, and more.
            </p>
          </a>
        </div>

        <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" routerLink="/marketing">
            <p><i class="ti-target lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">Marketing</h5>
            <p class="mb-0">The marketing module provides a one-stop shop for all your native marketing platform
              reporting.
            </p>
          </a>
        </div>

        <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" routerLink="/site">
            <p><i class="ti-layout lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">Site</h5>
            <p class="mb-0">Looking for information about your website performance and traffic analysis? You've come to
              the right place!</p>
          </a>
        </div>

        <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" routerLink="/tools">
            <p><i class="ti-panel lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">Tools</h5>
            <p class="mb-0">Provides access to tools to create audiences, campaigns, add costs, and much more. This is
              the part of the Sweet platform where you "do" rather than just "look"!</p>
          </a>
        </div>

      </div>
    </div>
  </section>

  <hr>

  <section class="section py-9">
    <div class="container">
      <header class="section-header">
        <h2>Other documentation</h2>
      </header>
      <div class="row gap-y text-center">

        <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" routerLink="/documentation">
            <p><i class="ti-package lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">Integration documentation</h5>
            <p class="mb-0">Understand how to connect the different third-party platforms you already use to Sweet.</p>
          </a>
        </div>

        <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" href="javascript:;">
            <p><i class="ti-book lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">Sweet Tracker</h5>
            <p class="mb-0">What is the Sweet Tracker and how does it work? Understand the importance of the tracker.
            </p>
          </a>
        </div>

        <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" routerLink="/knowledge-base">
            <p><i class="ti-book lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">Knowledge Base</h5>
            <p class="mb-0">Get started by reading the most popular articles about Sweet Analytics and how to get
              started.</p>
          </a>
        </div>

        <!-- <div class="col-md-4">
          <a class="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" routerLink="/help/faq">
            <p><i class="ti-help lead-7 text-primary"></i></p>
            <h5 class="fw-500 my-4">FAQ</h5>
            <p class="mb-0">Many questions have been asked before and have been answered already. Take a look to see if
              yours has also been raised.</p>
          </a>
        </div> -->

      </div>
    </div>
  </section>



  <section class="section">
    <div class="container">
      <header class="section-header">
        <h2>Get More Help</h2>
        <hr>
        <p class="lead">Here we have more ways to get help from real humans.</p>
      </header>

      <div class="row gap-y">

        <div class="col-md-6 col-lg-3">
          <a class="card card-sm card-body border hover-shadow-6 text-center py-6" href="javascript:;">
            <p><i class="fa fa-users lead-7 text-lighter"></i></p>
            <h6 class="mb-0 mt-3">Forums (Coming Soon)</h6>
          </a>
        </div>

        <div class="col-md-6 col-lg-3">
          <a class="card card-sm card-body border hover-shadow-6 text-center py-6" target="_blank"
            href="https://twitter.com/sweetanalytics">
            <p><i class="fa fa-twitter lead-7 text-lighter"></i></p>
            <h6 class="mb-0 mt-3">Twitter Support</h6>
          </a>
        </div>

        <div class="col-md-6 col-lg-3">
          <a class="card card-sm card-body border hover-shadow-6 text-center py-6" target="_blank"
            href="https://sweetanalytics.com/contact-us">
            <p><i class="fa fa-envelope lead-7 text-lighter"></i></p>
            <h6 class="mb-0 mt-3">Email Us</h6>
          </a>
        </div>

        <div class="col-md-6 col-lg-3">
          <a class="card card-sm card-body border hover-shadow-6 text-center py-6" target="_blank"
            href="https://app.sweetanalytics.com">
            <p><i class="fa fa-comments lead-7 text-lighter"></i></p>
            <h6 class="mb-0 mt-3">Live Chat</h6>
          </a>
        </div>

      </div>
    </div>
  </section>


</main><!-- /.main-content -->

<router-outlet></router-outlet>

<home-footer></home-footer>