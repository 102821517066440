import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { LoggedInGuard } from "./shared/guards/loggedIn.guard";
import { SuperAdminGuard } from "./shared/guards/super-admin.guard";
import { HomeLayoutComponent } from "./shared/templates/home-layout/home-layout.component";

const appRoutes: Routes = [
  {
    path: "",
    component: HomeLayoutComponent,
    canActivate: [],
  },
  {
    path: "sales",
    loadChildren: () =>
      import("./modules/sales/sales.module").then((m) => m.SalesModule),
  },
  {
    path: "roi",
    loadChildren: () =>
      import("./modules/roi/roi.module").then((m) => m.RoiModule),
  },
  {
    path: "customers",
    loadChildren: () =>
      import("./modules/customers/customers.module").then(
        (m) => m.CustomersModule
      ),
  },
  {
    path: "marketing",
    loadChildren: () =>
      import("./modules/marketing/marketing.module").then(
        (m) => m.MarketingModule
      ),
  },
  {
    path: "site",
    loadChildren: () =>
      import("./modules/site/site.module").then((m) => m.SiteModule),
  },
  {
    path: "tools",
    loadChildren: () =>
      import("./modules/tools/tools.module").then((m) => m.ToolsModule),
  },
  {
    path: "documentation",
    component: AppComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./modules/documentation/documentation.module").then(
            (m) => m.DocumentationModule
          ),
      },
      {
        path: "internal",
        canActivate: [SuperAdminGuard],
        loadChildren: () =>
          import("./modules/internal/internal.module").then(
            (m) => m.InternalModule
          ),
      },
    ],
  },
  {
    path: "knowledge-base",
    component: AppComponent,
    loadChildren: () =>
      import("./modules/knowledge-base/knowledge-base.module").then(
        (m) => m.KnowledgeBaseModule
      ),
  },
  {
    path: "help",
    component: AppComponent,
    loadChildren: () =>
      import("./modules/help/help.module").then((m) => m.HelpModule),
  },
  {
    path: "changelog",
    component: AppComponent,
    loadChildren: () =>
      import("./modules/changelog/changelog.module").then(
        (m) => m.ChangelogModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
    canActivate: [LoggedInGuard],
  },

  { path: "**", component: HomeLayoutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
