import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { DocsHeaderComponent } from "./components/docs-header/docs-header.component";
import { DocsTitleHeaderComponent } from "./components/docs-title-header/docs-title-header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { UTMParamsTableComponent } from "./components/utm-params-table/utm-params-table.component";
import { LoggedInGuard } from "./guards/loggedIn.guard";
import { SuperAdminGuard } from "./guards/super-admin.guard";
import { AuthService } from "./services/auth.service";
import { LocalStorageService } from "./services/local-storage.service";
import { HomeHeaderComponent } from "./templates/home-layout/header/header.component";
import { HomeLayoutComponent } from "./templates/home-layout/home-layout.component";
import { ModuleLayoutComponent } from "./templates/module-layout/module-layout.component";
import { HighlightModule } from "ngx-highlightjs";

const exportedComponents = [
  HomeHeaderComponent,
  HomeLayoutComponent,
  NavbarComponent,
  BreadcrumbComponent,
  DocsTitleHeaderComponent,
  DocsHeaderComponent,
  FooterComponent,
  UTMParamsTableComponent,
];

const modules = [
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  RouterModule,
  HighlightModule,
];

@NgModule({
  declarations: [...exportedComponents, ModuleLayoutComponent],
  imports: [...modules],
  providers: [SuperAdminGuard, LoggedInGuard, AuthService, LocalStorageService],
  exports: [...exportedComponents, ...modules],
})
export class SharedModule {}
