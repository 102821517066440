<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-{{theme}} navbar-stick-dark" data-navbar="sticky">
  <div class="container">

    <div class="navbar-left">
      <button class="navbar-toggler" type="button">&#9776;</button>
      <a class="navbar-brand" routerLink="/">
        <img class="logo-dark" src="assets/img/logo-dark.png" alt="logo">
        <img class="logo-light" src="assets/img/logo-light.png" alt="logo">
      </a>
    </div>

    <section class="navbar-mobile">
      <span class="navbar-divider d-mobile-none"></span>

      <ul class="nav nav-navbar">
        <li class="nav-item">
          <a class="nav-link" routerLink="/documentation">Documentation</a>
        </li>

        <li class="nav-item" *ngIf="isSuperAdmin">
          <a class="nav-link" routerLink="/documentation/internal">Internal Docs</a>
        </li>

      </ul>
    </section>

    <a class="btn btn-sm btn-round btn-success" href="https://app.sweetanalytics.com" target="_blank">Log in</a>

  </div>
</nav><!-- /.navbar -->