<!-- Header -->
<header class="header" style="background-image: linear-gradient(135deg, #f9f7ff 0%, #fff 50%, #f6f3ff 100%);">
  <div class="container">
    <div class="row align-items-center">

      <div class="col-lg-6">
        <h1>Welcome to Sweet Analytics Documentation Portal</h1>
        <p class="lead mt-5 mb-8">Have any questions or want to learn more about Sweet Analytics and
          our disruptive technology? You've come to the right place!</p>
        <p><a class="btn btn-lg btn-round btn-info" routerLink="/documentation">Learn More</a></p>
      </div>

      <div class="col-lg-5 ml-auto d-none d-lg-block">
        <img src="assets/img/1.png" alt="img">
      </div>

    </div>
  </div>
</header><!-- /.header -->