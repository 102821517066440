import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

declare var page: any;

@Component({
  selector: "docs-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "docs-app";
  code = `function myFunction() {
    document.getElementById("demo1").innerHTML = "Test 1!";
    document.getElementById("demo2").innerHTML = "Test 2!";
  }`;
  constructor(private router: Router) {}

  ngOnInit() {
    // Detect navigation change and scroll to top of page
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    page.init();
  }
}
