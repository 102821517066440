import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'home-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() theme = 'dark';

  public isSuperAdmin = false;

  constructor(private readonly authService: AuthService) { }

  ngOnInit() {
    this.isSuperAdmin = this.authService.isSuperAdmin();
  }

}
