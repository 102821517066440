import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'docs-header',
  templateUrl: './docs-header.component.html',
  styleUrls: ['./docs-header.component.scss']
})
export class DocsHeaderComponent implements OnInit {

  @Input() title;

  constructor() { }

  ngOnInit() {
  }

}
