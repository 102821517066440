import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthToken } from '../models/auth.model';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private readonly localStorageService: LocalStorageService) { }

  public getToken(): string | null {
    return this.localStorageService.getToken();
  }

  public setToken(token: string): void {
    this.localStorageService.setToken(token);
  }

  public get decodedToken(): AuthToken {
    if (this.getToken()) {
      return this.jwtHelper.decodeToken(this.getToken());
    }
  }

  public isTokenValid(): boolean {
    return this.getToken() && !this.jwtHelper.isTokenExpired(this.getToken());
  }

  public isSuperAdmin(): boolean {
    return this.isTokenValid() && this.decodedToken.isSuperAdmin === true;
  }

}
