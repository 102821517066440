import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'docs-title-header',
  templateUrl: './docs-title-header.component.html',
  styleUrls: ['./docs-title-header.component.scss']
})
export class DocsTitleHeaderComponent implements OnInit {

  @Input() title = 'Documentation Coming Soon';
  @Input() subtitle = 'Get ready for some amazing documentation.';

  constructor() { }

  ngOnInit() {
  }

}
