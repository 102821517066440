<!-- Footer -->
<footer class="footer">
  <div class="container">
    <div class="row gap-y align-items-center">

      <div class="col-md-9">
        <div class="nav justify-content-center justify-content-md-start">
          <a class="nav-link" routerLink="/documentation">Documentation</a>
          <!-- <a class="nav-link" routerLink="/new-features">What's New</a> -->
          <a class="nav-link" href="https://status.sweetanalytics.com" target="_blank">Status</a>
        </div>
      </div>

      <div class="col-md-3 text-center text-md-right">
        <a href="https://sweetanalytics.com">© Sweet Analytics</a>
      </div>
    </div>
  </div>
</footer><!-- /.footer -->