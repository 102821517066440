import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "docs-utm-params-table",
  templateUrl: './utm-params-table.component.html',
  styleUrls: ['./utm-params-table.component.scss']
})
export class UTMParamsTableComponent implements OnInit {
  @Input() source;
  @Input() medium;
  @Input() campaign;
  @Input() term;
  @Input() content;

  constructor() { }

  ngOnInit() { }


}
