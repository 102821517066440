import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { HIGHLIGHT_OPTIONS } from "ngx-highlightjs";

if (environment.production) {
  enableProdMode();
}

// const themeGithub: string = "node_modules/highlight.js/styles/github.css";

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    providers: [
      {
        provide: HIGHLIGHT_OPTIONS,
        useValue: {
          fullLibraryLoader: () => import("highlight.js"),
        },
        // useValue: {
        //   coreLibraryLoader: () => import("highlight.js/lib/core"),
        //   languages: {
        //     xml: () => import("highlight.js/lib/languages/xml"),
        //     typescript: () => import("highlight.js/lib/languages/typescript"),
        //     javascript: () => import("highlight.js/lib/languages/javascript"),
        //   },
        // },
      },
    ],
  })
  .catch((err) => console.error(err));
