<table class="table table-bordered table-striped table-responsive-md">
    <thead>
        <tr>
            <th>Source</th>
            <th>Medium</th>
            <th>Campaign</th>
            <th>Term</th>
            <th>Content</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{{ source ? source : 'N/A' }}</td>
            <td>{{ medium ? medium : 'N/A' }}</td>
            <td>{{ campaign ? campaign : 'N/A' }}</td>
            <td>{{ term ? term : 'N/A' }}</td>
            <td>{{ content ? content : 'N/A' }}</td>
        </tr>

    </tbody>
</table>