import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

export const TOKEN_KEY = 'token';
const APP_PREFIX = 'app-';

@Injectable()
export class LocalStorageService {
  private jwtHelper = new JwtHelperService();

  public setItem(key: string, value: any): void {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  public getItem(key: string) {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
  }

  public getToken(): string | null {
    return this.getItem(TOKEN_KEY);
  }
  
  public setToken(value: string): void {
    this.setItem(TOKEN_KEY, value);
  }

  public invalidateToken(): void {
    this.setItem(TOKEN_KEY, null);
  }
}
